import './App.css';

import { Panels } from './panels/_Panels';
import { TimelineBar } from './components/TimelineBar/TimelineBar';

const ROUTES_JSON = [
  ['Results', '[["results",100]]'],
  ['Calendar', '[["calendar",50],["results",50]]'],
  ['Collections', '[["collections",50],["results",50]]'],
  ['Advanced Search', '[["search",50],["results",50]]'],
  ['Viewer 2A', '[["results",50],["viewer",50]]'],
  ['Viewer 2B', '[["viewer",50],["properties",50]]'],
  ['Viewer 3', '[["results",20],["viewer",45],["properties",35]]'],
  ['Viewer 33.3', '[["results",33.3],["viewer",33.3],["properties",33.3]]'],
  ['Viewer Full', '[["viewer",100]]'],
];

const PROFILE_ROUTE_JSON = ['Profile', '[["profile",100]]'];

const isActive = ({ href }) => {
  const normalizedHref = `/${window.location.search.replace(/%22/g, '"')}`;

  return `/?workspaces=${href}` === normalizedHref;
};

const App = () => (
  <div className="App">
    <header className="AppBar">
      <nav>
        {ROUTES_JSON.map(([text, href], idx) => {
          return (
            <a
              key={`${text}-${idx}`}
              href={`/?workspaces=${href}`}
              data-active={isActive({ href })}
            >
              {text}
            </a>
          );
        })}
      </nav>
      <div>
        <a
          href={`/?workspaces=${PROFILE_ROUTE_JSON[1]}`}
          data-active={isActive({ href: PROFILE_ROUTE_JSON[1] })}
        >
          {PROFILE_ROUTE_JSON[0]}
        </a>
      </div>
    </header>
    <Panels />
    <TimelineBar />
  </div>
);

export default App;
