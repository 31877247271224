import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { workspaceManager } from './workspaceManager';

import './variables.css';
import './index.css';

// on app load, read the current URl & set the
// CSS variables that determine the widths of the workspace panels
workspaceManager.setPanelWidthsBasedOnCurrentUrl();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
