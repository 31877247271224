import { ChevronDown } from './ChevronDown';
import { ChevronLeft } from './ChevronLeft';
import { ChevronUp } from './ChevronUp';
import { ChevronRight } from './ChevronRight';

import { CloseX } from './CloseX';

import { workspaceManager } from '../../workspaceManager';

import './PanelHeaderButton.css';

const PanelHeaderButton = ({ panelName, variant }) => (
  <button
    className="PanelHeaderButton"
    data-panel-name={panelName}
    data-variant={variant}
    onClick={workspaceManager.onButtonClick}
  >
    {variant === 'down' && (
      <span className="PanelHeaderButton--down">
        <ChevronDown />
      </span>
    )}
    {variant === 'left' && (
      <span className="PanelHeaderButton--left">
        <ChevronLeft />
      </span>
    )}
    {variant === 'right' && (
      <span className="PanelHeaderButton--right">
        <ChevronRight />
      </span>
    )}
    {variant === 'up' && (
      <span className="PanelHeaderButton--up">
        <ChevronUp />
      </span>
    )}
    {variant === 'close' && (
      <span className="PanelHeaderButton--close">
        <CloseX />
      </span>
    )}
  </button>
);

// propTypes
// oneOf
// [ down, left, right, up, close ]

export { PanelHeaderButton };
