import { CalendarPanel } from './CalendarPanel';
import { CollectionsPanel } from './CollectionsPanel';
import { PropertiesPanel } from './PropertiesPanel';
import { ProfilePanel } from './ProfilePanel';
import { ResultsPanel } from './ResultsPanel';
import { SearchPanel } from './SearchPanel';
import { ViewerPanel } from './ViewerPanel';

import { PanelHeader } from '../components/PanelHeader/PanelHeader';

import { workspaceManager } from '../workspaceManager';

import './_Panels.css';

const PANEL_COMPONENTS = {
  CalendarPanel: <CalendarPanel />,
  CollectionsPanel: <CollectionsPanel />,
  PropertiesPanel: <PropertiesPanel />,
  ProfilePanel: <ProfilePanel />,
  ResultsPanel: <ResultsPanel />,
  SearchPanel: <SearchPanel />,
  ViewerPanel: <ViewerPanel />,
};

const PANEL_HEADINGS = {
  CalendarPanel: 'Calendar',
  CollectionsPanel: 'Collections',
  PropertiesPanel: 'Properties',
  ProfilePanel: 'Profile',
  ResultsPanel: 'Results',
  SearchPanel: 'Advanced Search',

  // TODO: the viewer shouldn't have the same heading
  ViewerPanel: 'Viewer',
};

const arrOfPanelNames = workspaceManager.getArrayOfPanelNamesFromCurrentUrl();

const Panels = () => (
  <div className="PanelWrapper">
    {arrOfPanelNames.map((panelName, idx) => {
      return (
        <div
          key={`${panelName}-${idx}`}
          data-panel-name={panelName}
          onTransitionEnd={workspaceManager.afterWidthResize}
        >
          <PanelHeader
            panelName={panelName}
            panelText={PANEL_HEADINGS[panelName]}
          />
          <div className="PanelContentWrapper">
            {PANEL_COMPONENTS[panelName]}
          </div>
        </div>
      );
    })}
  </div>
);

export { Panels };
