// http://localhost:3000/?workspaces=[["results",50],["properties",50]]
// http://localhost:3000/?workspaces=[["results",33.3],["viewer",33.3],["properties",33.3]]
// http://localhost:3000/?workspaces=[["results",20],["viewer",45],["properties",35]]

/**
 * reference to the element where our CSS variables are set
 * see the `:root` CSS selector
 *
 * https://css-tricks.com/updating-a-css-variable-with-javascript/
 */
const root = document.documentElement;

/**
 * gets the value of the CSS variable that determine a panel's width
 */
const getPanelWidth = ({ panelName }) =>
  parseFloat(getComputedStyle(root).getPropertyValue(`--width-${panelName}`));

const shouldRenderPanel = ({ panelName }) => {
  const panelWidth = getPanelWidth({ panelName });

  console.log({ panelName, panelWidth });

  if (!panelWidth) {
    return false;
  }

  // other conditional logic could go here

  return true;
};

/**
 * sets the CSS variable that determines a panel's width
 */
const setPanelWidth = ({ panelName, width }) =>
  root.style.setProperty(`--width-${panelName}`, `${width}%`);

/**
 * sets the CSS variable that determines a panel's width animation
 *
 * this transition is for:
 *   - loading the app
 *   - selecting a new dashboard configuration from the menu
 *   - instant navigation via the header menu
 */
const setWidthTransitionToInstant = () =>
  root.style.setProperty('--transition-duration--panel-widths', 0);

/**
 * sets the CSS variable that determines a panel's width animation
 *
 * this transition is for:
 *   - expanding or contracting a panel that is already open
 */
const setWidthTransitionToSmooth = () =>
  root.style.setProperty('--transition-duration--panel-widths', '500ms');

/**
 * parses "?workspaces=" from the current URl & returns an array of tuples
 */
const getWorkspaceConfigFromCurrentUrl = () => {
  // '[["results",20],["viewer",45],["properties",35]]'
  const jsonString = new URLSearchParams(document.location.search).get(
    'workspaces',
  );

  const arrayOfTuples = JSON.parse(jsonString);

  // consider validating and/or extracting "timeline" key

  // const KNOWN_PANELS = {
  //   calendar: true,
  //   collections: true,
  //   profile: true,
  //   properties: true,
  //   results: true,
  //   search: true,
  //   viewer: true,
  // };

  return arrayOfTuples;
};

/**
 * converts "results" to "ResultsPanel"
 * converts "properties" to "PropertiesPanel"
 */
const convertPanelNameFromUrlToComponentName = ({ panelNameFromUrl }) => {
  const asTitleCase = `${panelNameFromUrl
    .charAt(0)
    .toUpperCase()}${panelNameFromUrl.substr(1).toLowerCase()}`;

  return `${asTitleCase}Panel`;
};

/**
 * reads the current URl & sets the CSS variables
 * that determine the widths of the workspace panels
 *
 * http://localhost:3000/?workspaces=[["results",25],["viewer",35],["properties",20]]
 */
const setPanelWidthsBasedOnCurrentUrl = () => {
  getWorkspaceConfigFromCurrentUrl().forEach((entry) => {
    const [panelNameFromUrl, widthFromUrl] = entry;

    setPanelWidth({
      panelName: convertPanelNameFromUrlToComponentName({ panelNameFromUrl }),
      width: parseFloat(widthFromUrl),
    });
  });
};

/**
 * reads the current URl returns an array of strings
 *
 * these are the React Component names of our panels
 * in the order that they should be rendered
 *
 * ['ResultsPanel', 'ViewerPanel', 'PropertiesPanel']
 */
const getArrayOfPanelNamesFromCurrentUrl = () =>
  getWorkspaceConfigFromCurrentUrl().map((entry) =>
    convertPanelNameFromUrlToComponentName({ panelNameFromUrl: entry[0] }),
  );

const handleTimelineButtonClick = ({ variant }) => {
  const CSS_VARIABLE = '--height-TimelineBar';

  const HEIGHTS = {
    absent: '0',
    collapsed: '40px',
    expanded: '200px',
  };

  switch (variant) {
    case 'close':
      root.style.setProperty(CSS_VARIABLE, HEIGHTS.absent);

      break;
    case 'down':
      root.style.setProperty(CSS_VARIABLE, HEIGHTS.collapsed);

      break;
    case 'up':
      root.style.setProperty(CSS_VARIABLE, HEIGHTS.expanded);

      break;
    default:
      console.warn(`unknown button variant "${variant}"`);

      break;
  }
};

const onButtonClick = (evt) => {
  evt.stopPropagation();

  // these come from data attributes on the <button /> element
  const { panelName, variant } = evt.target.dataset;

  if (panelName === 'TimelineBar') {
    handleTimelineButtonClick({ variant });

    return;
  }

  const oldWidth = getPanelWidth({ panelName });

  console.log('onButtonClick', { panelName, variant, oldWidth });

  let newWidth;

  switch (variant) {
    case 'left':
      setWidthTransitionToSmooth();
      newWidth = oldWidth / 2;

      break;
    case 'right':
      setWidthTransitionToSmooth();
      newWidth = oldWidth * 2;

      break;
    case 'close':
      setWidthTransitionToInstant();
      newWidth = 0;

      break;
    default:
      console.warn(`unknown button variant "${variant}"`);

      break;
  }

  setPanelWidth({ panelName, width: newWidth });
};

const afterWidthResize = (evt) => {
  if (evt.propertyName !== 'width') {
    return;
  }

  // this comes from `data-panel-name` on the element
  const { panelName } = evt.target.dataset;

  const width = getPanelWidth({ panelName });

  console.log('afterWidthResize', { panelName, width });

  // TODO: get all widths and update the URL
};

const workspaceManager = {
  afterWidthResize,
  getArrayOfPanelNamesFromCurrentUrl,
  onButtonClick,
  setPanelWidthsBasedOnCurrentUrl,
  shouldRenderPanel,
};

export { workspaceManager };
