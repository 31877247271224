import { PanelHeaderButton } from '../PanelHeaderButton/PanelHeaderButton';

import './TimelineBar.css';

const TimelineBar = () => (
  <div className="TimelineBar">
    <div className="timeline-header">
      <div className="timeline-header__buttons">
        <PanelHeaderButton panelName={'TimelineBar'} variant="up" />
        <PanelHeaderButton panelName={'TimelineBar'} variant="down" />
        <PanelHeaderButton panelName={'TimelineBar'} variant="close" />
      </div>
      <div>Timeline</div>
    </div>
    <div className="timeline-body"></div>
  </div>
);

export { TimelineBar };
