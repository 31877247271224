import { PanelHeaderButton } from '../PanelHeaderButton/PanelHeaderButton';

import './PanelHeader.css';

// TODO: fix the viewer hack

const PanelHeader = ({ panelName, panelText }) => (
  <div className="PanelHeader">
    <div
      className="PanelHeader__text"
      style={{ visibility: panelName === 'ViewerPanel' ? 'hidden' : 'unset' }}
    >
      <h2>{panelText}</h2>
    </div>
    <div className="PanelHeader__buttons">
      <PanelHeaderButton panelName={panelName} variant="left" />
      <PanelHeaderButton panelName={panelName} variant="right" />
      <PanelHeaderButton panelName={panelName} variant="close" />
    </div>
  </div>
);

export { PanelHeader };
